import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '@/views/Login.vue';
import SystemAdmin from '@/views/SystemAdmin.vue';
import Front from '@/views/Front.vue';
import systemAdminRoutes from './systemAdmin';
import systemStaffRoutes from './systemStaff';
import frontRoutes from './front';
import SystemStaff from '@/views/SystemStaff.vue';
import Agency from '@/views/Agency.vue';
import agencyRoutes from './agency';
import Adcode from '@/views/Adcode.vue';
import adcodeRoutes from './adcode';


const routes = [
  {
    path: '',
    name: 'Front',
    component: Front,
    children: frontRoutes,
  },
  {
    path: '/admin/login',
    name: 'Login',
    component: UserLogin,
  },
  {
    path: '/system-admin',
    name: 'SystemAdmin',
    component: SystemAdmin,
    children: systemAdminRoutes,
  },
  // Định tuyến khác cho Staff Admin, Agency Admin, User, v.v.
  {
    path: '/system-staff',
    name: 'SystemStaff',
    component: SystemStaff,
    children: systemStaffRoutes,
  },
  {
    path: '/agency',
    name: 'AgencyLayout',
    component: Agency,
    children: agencyRoutes,
  },
  {
    path: '/adcode',
    name: 'AdcodeLayout',
    component: Adcode,
    children: adcodeRoutes,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const app = document.querySelector('.main-container');
  if (to.path === '/system-staff/message-list') {
    if (app) app.classList.add('message-list-bg');
  } else {
    if (app) app.classList.remove('message-list-bg');
  }
  next();
});

export default router;
