const frontRoutes = [
    {
        path: '',
        component: () => import('../components/front/FrontIndex.vue'),
    },
    {
        path: 'searches/search',
        component: () => import('../components/front/FrontSearch.vue'),
    },
    {
        path: 'pc/homes/rule',
        component: () => import('../components/front/FrontRule.vue'),
    },
    {
        path: 'pc/homes/privacy',
        component: () => import('../components/front/FrontPrivacy.vue'),
    },
    {
        path: 'pc/homes/company',
        component: () => import('../components/front/FrontCompany.vue'),
    },
    {
        path: 'auths/login',
        component: () => import('../components/front/FrontLogin.vue'),
    },
    {
        path: 'mypages/user_index',
        component: () => import('../components/front/MyPageIndex.vue'),
    },
    {
        path: 'profiles/user_menu',
        component: () => import('../components/front/ProfileUserMenu.vue'),
    },
    {
        path: 'profiles/user_edit_profile',
        component: () => import('../components/front/ProfileUserEditProfile.vue'),
    },
    {
        path: 'profiles/user_change_address',
        component: () => import('../components/front/ProfileUserChangeAddress.vue'),
    },
    {
        path: 'profiles/user_add_pc_address',
        component: () => import('../components/front/ProfileUserAddPcAddress.vue'),
    },
    {
        path: 'profiles/user_change_password',
        component: () => import('../components/front/ProfileUserChangePassword.vue'),
    },
    {
        path: 'profiles/user_add_self_profile_picture',
        component: () => import('../components/front/ProfileUserAddSelfProfilePicture.vue'),
    },
    {
        path: 'profiles/user_view_self_profile_picture',
        component: () => import('../components/front/ProfileUserViewSelfProfilePicture.vue'),
    },
    {
        path: 'profiles/user_edit_self_attach_picture',
        component: () => import('../components/front/ProfileUserEditSelfAttachPicture.vue'),
    },
    {
        path: 'profiles/user_edit_phone',
        component: () => import('../components/front/ProfileUserEditPhone.vue'),
    },
    {
        path: 'searches/user_search',
        component: () => import('../components/front/UserSearch.vue'),
    },
    {
        path: 'points/user_purchase_point',
        component: () => import('../components/front/UserPurrchasePoint.vue'),
    },
    {
        path: 'pages/user_member_level',
        component: () => import('../components/front/PagesUserIndividualMemberLevel.vue'),
    },
    {
        path: 'pages/user_payment',
        component: () => import('../components/front/PagesUserIndividualPayment.vue'),
    },
    {
        path: 'points/user_about_point',
        component: () => import('../components/front/UserAboutPoint.vue'),
    },
    {
        path: 'pages/user_mail_domain',
        component: () => import('../components/front/UserMailDomain.vue'),
    },
    {
        path: 'mailboxs/user_new_mailbox',
        component: () => import('../components/front/UserNewMailbox.vue'),
    },
    {
        path: 'mailboxs/user_read_mailbox',
        component: () => import('../components/front/UserReadMailbox.vue'),
    },
    {
        path: 'mailboxs/user_send_mailbox',
        component: () => import('../components/front/UserSendMailbox.vue'),
    },
    {
        path: 'mailboxs/user_info_mailbox',
        component: () => import('../components/front/UserInfoMailbox.vue'),
    },
    {
        path: 'mailboxs/user_inquiry_mailbox',
        component: () => import('../components/front/UserInquiryMailbox.vue'),
    },
];
  
export default frontRoutes;