<template>
  <div class="">
    <CommonHeader />
      <main>
          <router-view></router-view>
      </main>
  </div>
</template>
  
  <script>
  
  export default {
    name: 'FrontLayout',
    components: {
    },
  };
  </script>
  
  <style lang="scss" scoped>
  
  </style>
  