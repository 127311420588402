const systemAdminRoutes = [
    {
      path: 'operation-management',
      component: () => import('@/components/systemAdmin/OperationManagement/OperationManagement.vue'),
      children: [
        { path: 'revenue-calendar', component: () => import('@/components/systemAdmin/OperationManagement/RevenueCalendar.vue') },
        { path: 'hourly-aggregation', component: () => import('@/components/systemAdmin/OperationManagement/HourlyAggregation.vue') },
        { path: 'sales-calendar', component: () => import('@/components/systemAdmin/OperationManagement/SalesCalendar.vue') },
        { path: 'site-operation', component: () => import('@/components/systemAdmin/OperationManagement/SiteOperation.vue') },
        { path: 'hourly-site-operation', component: () => import('@/components/systemAdmin/OperationManagement/HourlySiteOperation.vue') },
        { path: 'user-operation', component: () => import('@/components/systemAdmin/OperationManagement/UserOperation.vue') },
        { path: 'user-operation-hourly', component: () => import('@/components/systemAdmin/OperationManagement/UserOperationHourly.vue') },
        { path: 'character-operation', component: () => import('@/components/systemAdmin/OperationManagement/CharacterOperation.vue') },
        { path: 'character-operation-hourly', component: () => import('@/components/systemAdmin/OperationManagement/CharacterOperationHourly.vue') },
        { path: 'staff-operation', component: () => import('@/components/systemAdmin/OperationManagement/StaffOperation.vue') },
        { path: 'staff-operation-hourly', component: () => import('@/components/systemAdmin/OperationManagement/StaffOperationHourly.vue') },
        { path: 'user-consumption-points', component: () => import('@/components/systemAdmin/OperationManagement/UserConsumptionPoints.vue') },
        { path: 'operation-status', component: () => import('@/components/systemAdmin/OperationManagement/OperationStatus.vue') },
        { path: 'email-distribution-summary', component: () => import('@/components/systemAdmin/OperationManagement/EmailDistributionSummary.vue') },
        { path: 'email-distribution-detail', component: () => import('@/components/systemAdmin/OperationManagement/EmailDistributionDetail.vue') },
        { path: 'sms-distribution-summary', component: () => import('@/components/systemAdmin/OperationManagement/SMSDistributionSummary.vue') },
      ],
    },
    {
      path: 'log-management',
      component: () => import('@/components/systemAdmin/LogManagement/LogManagement.vue'),
      children: [
        { path: 'point-log', component: () => import('@/components/systemAdmin/LogManagement/PointLog.vue') },
        { path: 'email-log', component: () => import('@/components/systemAdmin/LogManagement/EmailLog.vue') },
        { path: 'issued-points', component: () => import('@/components/systemAdmin/LogManagement/IssuedPoints.vue') },
        { path: 'bulk-update-history', component: () => import('@/components/systemAdmin/LogManagement/BulkUpdateHistory.vue') },
        { path: 'bulk-update-status', component: () => import('@/components/systemAdmin/LogManagement/BulkUpdateStatus.vue') },
        { path: 'deletion-list', component: () => import('@/components/systemAdmin/LogManagement/DeletionList.vue') },
        { path: 'deletion-log', component: () => import('@/components/systemAdmin/LogManagement/DeletionLog.vue') },
      ],
    },
    {
      path: 'member-management',
      component: () => import('@/components/systemAdmin/MemberManagement/MemberManagement.vue'),
      children: [
        {
          path: 'member-search',
          component: () => import('@/components/systemAdmin/MemberManagement/MemberSearch/MemberSearch.vue'),
          children: [
            { path: 'search', component: () => import('@/components/systemAdmin/MemberManagement/MemberSearch/MemberSearchIndex.vue') },
            { path: 'search-condition-stock', component: () => import('@/components/systemAdmin/MemberManagement/MemberSearch/SearchConditionStock.vue') },
            { path: 'promise-date-search', component: () => import('@/components/systemAdmin/MemberManagement/MemberSearch/PromiseDateSearch.vue') },
            { path: 'member-detail/:id?', component: () => import('@/components/systemAdmin/MemberManagement/MemberSearch/MemberDetail.vue') },
            { path: 'member-send-notification-email', component: () => import('@/components/systemAdmin/MemberManagement/MemberSearch/MemberSendNotiEmail.vue') },
            { path: 'member-mass-email-transmission', component: () => import('@/components/systemAdmin/MemberManagement/MemberSearch/MemberMassEmailTransmission.vue') },
          ],
        },
        {
          path: 'member-info-settings',
          component: () => import('@/components/systemAdmin/MemberManagement/MemberInfoSettings/MemberInfoSettings.vue'),
          children: [
            { path: 'member-level', component: () => import('@/components/systemAdmin/MemberManagement/MemberInfoSettings/MemberLevel.vue') },
            { path: 'member-group-name-settings', component: () => import('@/components/systemAdmin/MemberManagement/MemberInfoSettings/MemberGroupNameSettings.vue') },
            { path: 'profile', name: 'profiles', component: () => import('@/components/systemAdmin/MemberManagement/MemberInfoSettings/MemberProfile.vue') },
            { path: 'profile-edit/:id?', name: 'profile-edit', component: () => import('@/components/systemAdmin/MemberManagement/MemberInfoSettings/MemberProfileEdit.vue') },
            { path: 'bulletin-board-comments', component: () => import('@/components/systemAdmin/MemberManagement/MemberInfoSettings/BulletinBoardComments.vue') },
            { path: 'photo-mail', component: () => import('@/components/systemAdmin/MemberManagement/MemberInfoSettings/PhotoMail.vue') },
            { path: 'point-correction', component: () => import('@/components/systemAdmin/MemberManagement/MemberInfoSettings/PointCorrection.vue') },
            { path: 'free-code-edit', component: () => import('@/components/systemAdmin/MemberManagement/MemberInfoSettings/FreeCodeEdit.vue') },
            { path: 'free-word-settings', component: () => import('@/components/systemAdmin/MemberManagement/MemberInfoSettings/FreeWordSettings.vue') },
            { path: 'free-word-settings-edit', component: () => import('@/components/systemAdmin/MemberManagement/MemberInfoSettings/FreeWordSettingsEdit.vue') },
            { path: 'member-group-order-settings', component: () => import('@/components/systemAdmin/MemberManagement/MemberInfoSettings/MemberGroupOrderSettings.vue') },
          ],
        },
        {
          path: 'inquiry',
          component: () => import('@/components/systemAdmin/MemberManagement/Inquiry/InquiryIndex.vue'),
          children: [
            { path: 'inquiry-management', component: () => import('@/components/systemAdmin/MemberManagement/Inquiry/InquiryManagement.vue') },
            { path: 'inquiry-rejection-management', component: () => import('@/components/systemAdmin/MemberManagement/Inquiry/InquiryRejectionManagement.vue') },
            { path: 'inquiry-rejection-management-create', component: () => import('@/components/systemAdmin/MemberManagement/Inquiry/InquiryRejectionManagementCreate.vue') },
            { path: 'inquiry-subject-settings', component: () => import('@/components/systemAdmin/MemberManagement/Inquiry/InquirySubjectSettings.vue') },
          ],
        },
        {
          path: 'member-deletion-settings',
          component: () => import('@/components/systemAdmin/MemberManagement/MemberDeletionSettings/MemberDeletionSettings.vue'),
          children: [
            { path: 'auto-member-deletion-settings', component: () => import('@/components/systemAdmin/MemberManagement/MemberDeletionSettings/AutoMemberDeletionSettings.vue') },
            { path: 'auto-member-deletion-settings-create', component: () => import('@/components/systemAdmin/MemberManagement/MemberDeletionSettings/AutoMemberDeletionSettingsCreate.vue') },
            { path: 'auto-member-deletion-history', component: () => import('@/components/systemAdmin/MemberManagement/MemberDeletionSettings/AutoMemberDeletionHistory.vue') },
          ],
        },
        {
          path: 'auto-member-level-change-settings',
          component: () => import('@/components/systemAdmin/MemberManagement/AutoMemberLevelChangeSettings/AutoMemberLevelChangeSettings.vue'),
          children: [
            { path: 'auto-member-level-change-management', component: () => import('@/components/systemAdmin/MemberManagement/AutoMemberLevelChangeSettings/AutoMemberLevelChangeManagement.vue') },
            { path: 'auto-member-level-change-create', component: () => import('@/components/systemAdmin/MemberManagement/AutoMemberLevelChangeSettings/AutoMemberLevelChangeCreate.vue') },
            { path: 'auto-member-level-change-edit', component: () => import('@/components/systemAdmin/MemberManagement/AutoMemberLevelChangeSettings/AutoMemberLevelChangeEdit.vue') },
            { path: 'auto-member-level-change-history', component: () => import('@/components/systemAdmin/MemberManagement/AutoMemberLevelChangeSettings/AutoMemberLevelChangeHistory.vue') },
          ],
        },
        {
          path: 'auto-member-group-change-settings',
          component: () => import('@/components/systemAdmin/MemberManagement/AutoMemberGroupChangeSettings/AutoMemberGroupChangeSettings.vue'),
          children: [
            { path: 'auto-member-group-change-management', component: () => import('@/components/systemAdmin/MemberManagement/AutoMemberGroupChangeSettings/AutoMemberGroupChangeManagement.vue') },
            { path: 'auto-member-group-change-create', component: () => import('@/components/systemAdmin/MemberManagement/AutoMemberGroupChangeSettings/AutoMemberGroupChangeCreate.vue') },
            { path: 'auto-member-group-change-edit', component: () => import('@/components/systemAdmin/MemberManagement/AutoMemberGroupChangeSettings/AutoMemberGroupChangeEdit.vue') },
            { path: 'auto-member-group-change-history', component: () => import('@/components/systemAdmin/MemberManagement/AutoMemberGroupChangeSettings/AutoMemberGroupChangeHistory.vue') },
          ],
        },
      ],
    },
    {
      path: 'character-management',
      component: () => import('@/components/systemAdmin/CharacterManagement/CharacterManagement.vue'),
      children: [
        { path: 'character-search', component: () => import('@/components/systemAdmin/CharacterManagement/CharacterSearch.vue') },
        { path: 'character-search-create', component: () => import('@/components/systemAdmin/CharacterManagement/CharacterSearchCreate.vue') },
        { path: 'character-search-edit/:id?', component: () => import('@/components/systemAdmin/CharacterManagement/CharacterSearchEdit.vue') },
        { path: 'pair-search', component: () => import('@/components/systemAdmin/CharacterManagement/PairSearch.vue') },
        { path: 'character-box', component: () => import('@/components/systemAdmin/CharacterManagement/CharacterBox.vue') },
        { path: 'email-box', component: () => import('@/components/systemAdmin/CharacterManagement/EmailBox.vue') },
        { path: 'multi-character-area-settings', component: () => import('@/components/systemAdmin/CharacterManagement/MultiCharacterAreaSettings.vue') },
        { path: 'landmark-editing', component: () => import('@/components/systemAdmin/CharacterManagement/LandmarkEditing.vue') },
        { path: 'pair-level-settings', component: () => import('@/components/systemAdmin/CharacterManagement/PairLevelSettings.vue') },
        { path: 'stock-message-category', component: () => import('@/components/systemAdmin/CharacterManagement/StockMessageCategory.vue') },
        { path: 'pair-info-memo-title-settings', component: () => import('@/components/systemAdmin/CharacterManagement/PairInfoMemoTitleSettings.vue') },
        { path: 'stock-message-category-order', component: () => import('@/components/systemAdmin/CharacterManagement/StockMessageCategoryOrder.vue') },
      ],
    },
    {
      path: 'data-migration-management',
      component: () => import('@/components/systemAdmin/DataMigrationManagement/DataMigrationManagement.vue'),
      children: [
        { path: 'standard-user', component: () => import('@/components/systemAdmin/DataMigrationManagement/StandardUser.vue') },
        { path: 'standard-character', component: () => import('@/components/systemAdmin/DataMigrationManagement/StandardCharacter.vue') },
        { path: 'old-spec-user', component: () => import('@/components/systemAdmin/DataMigrationManagement/OldSpecUser.vue') },
        { path: 'new-spec-user', component: () => import('@/components/systemAdmin/DataMigrationManagement/NewSpecUser.vue') },
        { path: 'data-migration-history', component: () => import('@/components/systemAdmin/DataMigrationManagement/DataMigrationHistory.vue') },
      ],
    },
    {
      path: 'payment-management',
      component: () => import('@/components/systemAdmin/PaymentManagement/PaymentManagement.vue'),
      children: [
        { path: 'payment-management', component: () => import('@/components/systemAdmin/PaymentManagement/PaymentManagementIndex.vue') },
        { path: 'payment-amount-pattern-settings', component: () => import('@/components/systemAdmin/PaymentManagement/PaymentAmountPatternSettings.vue') },
        { path: 'payment-amount-pattern-settings-create', component: () => import('@/components/systemAdmin/PaymentManagement/PaymentAmountPatternSettingsCreate.vue') },
        { path: 'payment-amount-pattern-settings-edit', component: () => import('@/components/systemAdmin/PaymentManagement/PaymentAmountPatternSettingsEdit.vue') },
        { path: 'payment-order-settings', component: () => import('@/components/systemAdmin/PaymentManagement/PaymentOrderSettings.vue') },
        {
          path: 'postpaid',
          component: () => import('@/components/systemAdmin/PaymentManagement/Postpaid/PostpaidIndex.vue'),
          children: [
            { path: 'postpaid-management', component: () => import('@/components/systemAdmin/PaymentManagement/Postpaid/PostpaidManagement.vue') },
            { path: 'postpaid-management-history', component: () => import('@/components/systemAdmin/PaymentManagement/Postpaid/PostpaidManagementHistory.vue') },
          ],
        },
        {
          path: 'various-payment-management',
          component: () => import('@/components/systemAdmin/PaymentManagement/VariousPaymentManagement/VariousPaymentManagement.vue'),
          children: [
            { path: 'payment-list', component: () => import('@/components/systemAdmin/PaymentManagement/VariousPaymentManagement/PaymentList.vue') },
          ],
        },
        {
          path: 'bank-transfer',
          component: () => import('@/components/systemAdmin/PaymentManagement/BankTransfer/BankTransfer.vue'),
          children: [
            { path: 'settings', component: () => import('@/components/systemAdmin/PaymentManagement/BankTransfer/BankTransferSettings.vue') },
            { path: 'history', component: () => import('@/components/systemAdmin/PaymentManagement/BankTransfer/BankTransferHistory.vue') },
            { path: 'purchase-complete-email', component: () => import('@/components/systemAdmin/PaymentManagement/BankTransfer/PurchaseCompleteEmail.vue') },
            { path: 'member-level-based-payment-amount-settings', component: () => import('@/components/systemAdmin/PaymentManagement/BankTransfer/MemberLevelBasedPaymentAmountSettings.vue') },
          ],
        },
      ],
    },
    {
      path: 'ad-management',
      component: () => import('@/components/systemAdmin/AdManagement/AdManagement.vue'),
      children: [
        { path: 'registration-summary', component: () => import('@/components/systemAdmin/AdManagement/RegistrationSummary.vue') },
        { path: 'registration-summary-list', component: () => import('@/components/systemAdmin/AdManagement/RegistrationSummaryList.vue') },
        { path: 'revenue-summary', component: () => import('@/components/systemAdmin/AdManagement/RevenueSummary.vue') },
        { path: 'agency-management', component: () => import('@/components/systemAdmin/AdManagement/AgencyManagement.vue') },
        { path: 'agency-create', component: () => import('@/components/systemAdmin/AdManagement/AgencyCreate.vue') },
        { path: 'agency-edit/:id?', component: () => import('@/components/systemAdmin/AdManagement/AgencyEdit.vue') },
        { path: 'ad-code-management', component: () => import('@/components/systemAdmin/AdManagement/AdCodeManagement.vue') },
        { path: 'ad-code-create', component: () => import('@/components/systemAdmin/AdManagement/AdCodeCreate.vue') },
        { path: 'ad-code-edit/:id?', component: () => import('@/components/systemAdmin/AdManagement/AdCodeEdit.vue') },
      ],
    },
    {
      path: 'email-management',
      component: () => import('@/components/systemAdmin/EmailManagement/EmailManagement.vue'),
      children: [
        {
          path: 'template-management',
          component: () => import('@/components/systemAdmin/EmailManagement/TemplateManagement/TemplateManagement.vue'),
          children: [
            { path: 'notification-email', component: () => import('@/components/systemAdmin/EmailManagement/TemplateManagement/NotificationEmail.vue') },
            { path: 'notification-email-create', component: () => import('@/components/systemAdmin/EmailManagement/TemplateManagement/NotificationEmailCreate.vue') },
            { path: 'notification-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/TemplateManagement/NotificationEmailEdit.vue') },
            { path: 'inquiry-email', component: () => import('@/components/systemAdmin/EmailManagement/TemplateManagement/InquiryEmail.vue') },
            { path: 'inquiry-email-create', component: () => import('@/components/systemAdmin/EmailManagement/TemplateManagement/InquiryEmailCreate.vue') },
            { path: 'inquiry-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/TemplateManagement/InquiryEmailEdit.vue') },
            { path: 'random-text', component: () => import('@/components/systemAdmin/EmailManagement/TemplateManagement/RandomText.vue') },
            { path: 'random-text-create', component: () => import('@/components/systemAdmin/EmailManagement/TemplateManagement/RandomTextCreate.vue') },
            { path: 'random-text-edit', component: () => import('@/components/systemAdmin/EmailManagement/TemplateManagement/RandomTextEdit.vue') },
          ],
        },
        {
          path: 'email-distribution',
          component: () => import('@/components/systemAdmin/EmailManagement/EmailDistribution/EmailDistribution.vue'),
          children: [
            { path: 'staff-timer-send-list', component: () => import('@/components/systemAdmin/EmailManagement/EmailDistribution/StaffTimerSendList.vue') },
            { path: 'notification-email-send-history', component: () => import('@/components/systemAdmin/EmailManagement/EmailDistribution/NotificationEmailSendHistory.vue') },
            { path: 'notification-email-send-history-detail', component: () => import('@/components/systemAdmin/EmailManagement/EmailDistribution/NotificationEmailSendHistoryDetail.vue') },
            { path: 'bulk-email-send-history', component: () => import('@/components/systemAdmin/EmailManagement/EmailDistribution/BulkEmailSendHistory.vue') },
          ],
        },
        {
          path: 'step-email',
          component: () => import('@/components/systemAdmin/EmailManagement/StepEmail/StepEmail.vue'),
          children: [
            { path: 'notification-step-email', component: () => import('@/components/systemAdmin/EmailManagement/StepEmail/NotificationStepEmail.vue') },
            { path: 'notification-step-email-create', component: () => import('@/components/systemAdmin/EmailManagement/StepEmail/NotificationStepEmailCreate.vue') },
            { path: 'notification-step-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/StepEmail/NotificationStepEmailEdit.vue') },
            { path: 'notification-step-email-history', component: () => import('@/components/systemAdmin/EmailManagement/StepEmail/NotificationStepEmailHistory.vue') },
            { path: 'notification-step-email-history-detail', component: () => import('@/components/systemAdmin/EmailManagement/StepEmail/NotificationStepEmailHistoryDetail.vue') },
            { path: 'bulk-step-email', component: () => import('@/components/systemAdmin/EmailManagement/StepEmail/BulkStepEmail.vue') },
            { path: 'bulk-step-email-create', component: () => import('@/components/systemAdmin/EmailManagement/StepEmail/BulkStepEmailCreate.vue') },
            { path: 'bulk-step-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/StepEmail/BulkStepEmailEdit.vue') },
            { path: 'bulk-step-email-history', component: () => import('@/components/systemAdmin/EmailManagement/StepEmail/BulkStepEmailHistory.vue') },
            { path: 'bulk-step-email-history-detail', component: () => import('@/components/systemAdmin/EmailManagement/StepEmail/BulkStepEmailHistoryDetail.vue') },
            { path: 'step-email-group', component: () => import('@/components/systemAdmin/EmailManagement/StepEmail/StepEmailGroup.vue') },
            { path: 'step-email-group-create', component: () => import('@/components/systemAdmin/EmailManagement/StepEmail/StepEmailGroupCreate.vue') },
            { path: 'step-email-group-edit', component: () => import('@/components/systemAdmin/EmailManagement/StepEmail/StepEmailGroupEdit.vue') },
          ],
        },
        {
          path: 'scheduled-email',
          component: () => import('@/components/systemAdmin/EmailManagement/ScheduledEmail/ScheduledEmail.vue'),
          children: [
            { path: 'notification-scheduled-email', component: () => import('@/components/systemAdmin/EmailManagement/ScheduledEmail/NotificationScheduledEmail.vue') },
            { path: 'notification-scheduled-email-create', component: () => import('@/components/systemAdmin/EmailManagement/ScheduledEmail/NotificationScheduledEmailCreate.vue') },
            { path: 'notification-scheduled-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/ScheduledEmail/NotificationScheduledEmailEdit.vue') },
            { path: 'notification-scheduled-email-history', component: () => import('@/components/systemAdmin/EmailManagement/ScheduledEmail/NotificationScheduledEmailHistory.vue') },
            { path: 'notification-scheduled-email-history-detail', component: () => import('@/components/systemAdmin/EmailManagement/ScheduledEmail/NotificationScheduledEmailHistoryDetail.vue') },
            { path: 'bulk-scheduled-email', component: () => import('@/components/systemAdmin/EmailManagement/ScheduledEmail/BulkScheduledEmail.vue') },
            { path: 'bulk-scheduled-email-create', component: () => import('@/components/systemAdmin/EmailManagement/ScheduledEmail/BulkScheduledEmailCreate.vue') },
            { path: 'bulk-scheduled-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/ScheduledEmail/BulkScheduledEmailEdit.vue') },
            { path: 'bulk-scheduled-email-history', component: () => import('@/components/systemAdmin/EmailManagement/ScheduledEmail/BulkScheduledEmailHistory.vue') },
            { path: 'scheduled-email-group', component: () => import('@/components/systemAdmin/EmailManagement/ScheduledEmail/ScheduledEmailGroup.vue') },
            { path: 'scheduled-email-group-create', component: () => import('@/components/systemAdmin/EmailManagement/ScheduledEmail/ScheduledEmailGroupCreate.vue') },
            { path: 'scheduled-email-group-edit', component: () => import('@/components/systemAdmin/EmailManagement/ScheduledEmail/ScheduledEmailGroupEdit.vue') },
          ],
        },
        {
          path: 'new-arrival-notification-email',
          component: () => import('@/components/systemAdmin/EmailManagement/NewArrivalNotificationEmail/NewArrivalNotificationEmail.vue'),
          children: [
            { path: 'index', component: () => import('@/components/systemAdmin/EmailManagement/NewArrivalNotificationEmail/NewArrivalNotificationEmailIndex.vue') },
            { path: 'create', component: () => import('@/components/systemAdmin/EmailManagement/NewArrivalNotificationEmail/NewArrivalNotificationEmailCreate.vue') },
            { path: 'edit', component: () => import('@/components/systemAdmin/EmailManagement/NewArrivalNotificationEmail/NewArrivalNotificationEmailEdit.vue') },
            { path: 'config-staff', component: () => import('@/components/systemAdmin/EmailManagement/NewArrivalNotificationEmail/ConfigStaff.vue') },
            { path: 'config', component: () => import('@/components/systemAdmin/EmailManagement/NewArrivalNotificationEmail/ConfigView.vue') },
            { path: 'config-pre', component: () => import('@/components/systemAdmin/EmailManagement/NewArrivalNotificationEmail/ConfigPre.vue') },
          ],
        },
        {
          path: 'remail-management',
          component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/RemailManagement.vue'),
          children: [
            { path: 'pre-registration-completion-email', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/PreRegistrationCompletionEmail.vue') },
            { path: 'pre-registration-completion-email-create', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/PreRegistrationCompletionEmailCreate.vue') },
            { path: 'pre-registration-completion-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/PreRegistrationCompletionEmailEdit.vue') },
            { path: 'registration-completion-email', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/RegistrationCompletionEmail.vue') },
            { path: 'registration-completion-email-create', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/RegistrationCompletionEmailCreate.vue') },
            { path: 'registration-completion-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/RegistrationCompletionEmailEdit.vue') },
            { path: 'withdrawal-email', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/WithdrawalEmail.vue') },
            { path: 'withdrawal-email-create', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/WithdrawalEmailCreate.vue') },
            { path: 'withdrawal-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/WithdrawalEmailEdit.vue') },
            { path: 'bank-information-email', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/BankInformationEmail.vue') },
            { path: 'bank-information-email-create', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/BankInformationEmailCreate.vue') },
            { path: 'bank-information-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/BankInformationEmailEdit.vue') },
            { path: 'invalid-destination-auto-reply-email', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/InvalidDestinationAutoReplyEmail.vue') },
            { path: 'invalid-destination-auto-reply-email-create', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/InvalidDestinationAutoReplyEmailCreate.vue') },
            { path: 'invalid-destination-auto-reply-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/InvalidDestinationAutoReplyEmailEdit.vue') },
            { path: 'purchase-completion-email', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/PurchaseCompletionEmail.vue') },
            { path: 'purchase-completion-email-create', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/PurchaseCompletionEmailCreate.vue') },
            { path: 'purchase-completion-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/PurchaseCompletionEmailEdit.vue') },
            { path: 'password-reminder-email', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/PasswordReminderEmail.vue') },
            { path: 'password-reminder-email-create', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/PasswordReminderEmailCreate.vue') },
            { path: 'password-reminder-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/PasswordReminderEmailEdit.vue') },
            { path: 'insufficient-points-image-registration-email', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/InsufficientPointsImageRegistrationEmail.vue') },
            { path: 'insufficient-points-image-registration-email-create', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/InsufficientPointsImageRegistrationEmailCreate.vue') },
            { path: 'insufficient-points-image-registration-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/InsufficientPointsImageRegistrationEmailEdit.vue') },
            { path: 'pc-address-registration-unavailable-email', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/PCAddressRegistrationUnavailableEmail.vue') },
            { path: 'pc-address-registration-unavailable-email-create', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/PCAddressRegistrationUnavailableEmailCreate.vue') },
            { path: 'pc-address-registration-unavailable-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/PCAddressRegistrationUnavailableEmailEdit.vue') },
            { path: 'withdrawn-email', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/WithdrawnEmail.vue') },
            { path: 'withdrawn-email-create', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/WithdrawnEmailCreate.vue') },
            { path: 'withdrawn-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/WithdrawnEmailEdit.vue') },
            { path: 'registered-email', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/RegisteredEmail.vue') },
            { path: 'registered-email-create', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/RegisteredEmailCreate.vue') },
            { path: 'registered-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/RegisteredEmailEdit.vue') },
            { path: 'image-registration-error-email', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/ImageRegistrationErrorEmail.vue') },
            { path: 'image-registration-error-email-create', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/ImageRegistrationErrorEmailCreate.vue') },
            { path: 'image-registration-error-email-edit', component: () => import('@/components/systemAdmin/EmailManagement/RemailManagement/ImageRegistrationErrorEmailEdit.vue') },
          ],
        },
        {
          path: 'sms-notification-email',
          component: () => import('@/components/systemAdmin/EmailManagement/SMSNotificationEmail/SMSNotificationEmail.vue'),
          children: [
            { path: 'index', component: () => import('@/components/systemAdmin/EmailManagement/SMSNotificationEmail/SMSNotificationEmailIndex.vue') },
            { path: 'create', component: () => import('@/components/systemAdmin/EmailManagement/SMSNotificationEmail/SMSNotificationEmailIndexCreate.vue') },
            { path: 'edit', component: () => import('@/components/systemAdmin/EmailManagement/SMSNotificationEmail/SMSNotificationEmailIndexEdit.vue') },
            { path: 'config', component: () => import('@/components/systemAdmin/EmailManagement/SMSNotificationEmail/SMSNotificationEmailConfig.vue') },
          ],
        },
        {
          path: 'auto-reply-email',
          component: () => import('@/components/systemAdmin/EmailManagement/AutoReplyEmail/AutoReplyEmail.vue'),
          children: [
            { path: 'notification-email-management', component: () => import('@/components/systemAdmin/EmailManagement/AutoReplyEmail/NotificationEmailManagement.vue') },
            { path: 'notification-email-management-create', component: () => import('@/components/systemAdmin/EmailManagement/AutoReplyEmail/NotificationEmailManagementCreate.vue') },
            { path: 'notification-email-management-edit', component: () => import('@/components/systemAdmin/EmailManagement/AutoReplyEmail/NotificationEmailManagementEdit.vue') },
            { path: 'notification-email-history', component: () => import('@/components/systemAdmin/EmailManagement/AutoReplyEmail/NotificationEmailHistory.vue') },
            { path: 'notification-email-history-detail', component: () => import('@/components/systemAdmin/EmailManagement/AutoReplyEmail/NotificationEmailHistoryDetail.vue') },
            { path: 'bulk-email-management', component: () => import('@/components/systemAdmin/EmailManagement/AutoReplyEmail/BulkEmailManagement.vue') },
            { path: 'bulk-email-management-create', component: () => import('@/components/systemAdmin/EmailManagement/AutoReplyEmail/BulkEmailManagementCreate.vue') },
            { path: 'bulk-email-management-edit', component: () => import('@/components/systemAdmin/EmailManagement/AutoReplyEmail/BulkEmailManagementEdit.vue') },
            { path: 'bulk-email-history', component: () => import('@/components/systemAdmin/EmailManagement/AutoReplyEmail/BulkEmailHistory.vue') },
            { path: 'bulk-email-history-detail', component: () => import('@/components/systemAdmin/EmailManagement/AutoReplyEmail/BulkEmailHistoryDetail.vue') },
          ],
        },
      ],
    },
    {
      path: 'page-management',
      component: () => import('@/components/systemAdmin/PageManagement/PageManagement.vue'),
      children: [
        {
          path: 'standard-page-management',
          component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/StandardPageManagement.vue'),
          children: [
            { path: 'top-page', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/TopPage.vue') },
            { path: 'top-page-create/:device_type?', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/TopPageCreate.vue') },
            { path: 'top-page-edit/:device_type/:id', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/TopPageEdit.vue') },
            { path: 'terms-of-service-page', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/TermsOfServicePage.vue') },
            { path: 'terms-of-service-page-create', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/TermsOfServicePageCreate.vue') },
            { path: 'terms-of-service-page-edit', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/TermsOfServicePageEdit.vue') },
            { path: 'operator-information-page', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/OperatorInformationPage.vue') },
            { path: 'operator-information-page-create', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/OperatorInformationPageCreate.vue') },
            { path: 'operator-information-page-edit', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/OperatorInformationPageEdit.vue') },
            { path: 'privacy-policy-page', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PrivacyPolicyPage.vue') },
            { path: 'privacy-policy-page-create', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PrivacyPolicyPageCreate.vue') },
            { path: 'privacy-policy-page-edit', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PrivacyPolicyPageEdit.vue') },
            { path: 'bank-information-page', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/BankInformationPage.vue') },
            { path: 'bank-information-page-create', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/BankInformationPageCreate.vue') },
            { path: 'bank-information-page-edit', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/BankInformationPageEdit.vue') },
            { path: 'points-addition-page', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PointsAdditionPage.vue') },
            { path: 'points-addition-page-create', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PointsAdditionPageCreate.vue') },
            { path: 'points-addition-page-edit', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PointsAdditionPageEdit.vue') },
            { path: 'mail-refusal-settings-page', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/MailRefusalSettingsPage.vue') },
            { path: 'mail-refusal-settings-page-create', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/MailRefusalSettingsPageCreate.vue') },
            { path: 'mail-refusal-settings-page-edit', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/MailRefusalSettingsPageEdit.vue') },
            { path: 'post-payment-buffer-page', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PostPaymentBufferPage.vue') },
            { path: 'post-payment-buffer-page-create', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PostPaymentBufferPageCreate.vue') },
            { path: 'post-payment-buffer-page-edit', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PostPaymentBufferPageEdit.vue') },
            { path: 'withdrawal-page', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/WithdrawalPage.vue') },
            { path: 'withdrawal-page-create', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/WithdrawalPageCreate.vue') },
            { path: 'withdrawal-page-edit', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/WithdrawalPageEdit.vue') },
            { path: 'mail-completion-page', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/MailCompletionPage.vue') },
            { path: 'mail-completion-page-create', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/MailCompletionPageCreate.vue') },
            { path: 'mail-completion-page-edit', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/MailCompletionPageEdit.vue') },
            { path: 'present-completion-page', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PresentCompletionPage.vue') },
            { path: 'present-completion-page-create', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PresentCompletionPageCreate.vue') },
            { path: 'present-completion-page-edit', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PresentCompletionPageEdit.vue') },
            { path: 'post-payment-settings-page', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PostPaymentSettingsPage.vue') },
            { path: 'post-payment-settings-page-create', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PostPaymentSettingsPageCreate.vue') },
            { path: 'post-payment-settings-page-edit', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PostPaymentSettingsPageEdit.vue') },
            { path: 'points-about-member-page', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PointsAboutMemberPage.vue') },
            { path: 'points-about-member-page-create', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PointsAboutMemberPageCreate.vue') },
            { path: 'points-about-member-page-edit', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PointsAboutMemberPageEdit.vue') },
            { path: 'points-about-non-member-page', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PointsAboutNonMemberPage.vue') },
            { path: 'points-about-non-member-page-create', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PointsAboutNonMemberPageCreate.vue') },
            { path: 'points-about-non-member-page-edit', component: () => import('@/components/systemAdmin/PageManagement/StandardPageManagement/PointsAboutNonMemberPageEdit.vue') },
          ],
        },
        {
          path: 'registration-page-management',
          component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/RegistrationPageManagement.vue'),
          children: [
            { path: 'temporary-registration-form-before', component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/TemporaryRegistrationFormBefore.vue') },
            { path: 'temporary-registration-form-before-create', component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/TemporaryRegistrationFormBeforeCreate.vue') },
            { path: 'temporary-registration-form-before-edit', component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/TemporaryRegistrationFormBeforeEdit.vue') },
            { path: 'temporary-registration-form-after', component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/TemporaryRegistrationFormAfter.vue') },
            { path: 'temporary-registration-form-after-create', component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/TemporaryRegistrationFormAfterCreate.vue') },
            { path: 'temporary-registration-form-after-edit', component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/TemporaryRegistrationFormAfterEdit.vue') },
            { path: 'temporary-registration-header', component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/TemporaryRegistrationHeader.vue') },
            { path: 'temporary-registration-header-create', component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/TemporaryRegistrationHeaderCreate.vue') },
            { path: 'temporary-registration-header-edit', component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/TemporaryRegistrationHeaderEdit.vue') },
            { path: 'final-registration-header', component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/FinalRegistrationHeader.vue') },
            { path: 'final-registration-header-create', component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/FinalRegistrationHeaderCreate.vue') },
            { path: 'final-registration-header-edit', component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/FinalRegistrationHeaderEdit.vue') },
            { path: 'api-registration-completion-text', component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/APIRegistrationCompletionText.vue') },
            { path: 'api-registration-completion-text-create', component: () => import('@/components/systemAdmin/PageManagement/RegistrationPageManagement/APIRegistrationCompletionTextCreate.vue') },
          ],
        },
        {
          path: 'my-page-management',
          component: () => import('@/components/systemAdmin/PageManagement/MyPageManagement/MyPageManagement.vue'),
          children: [
            { path: 'login-top-page-creation', component: () => import('@/components/systemAdmin/PageManagement/MyPageManagement/LoginTopPageCreation.vue') },
            { path: 'login-top-page-creation-create', component: () => import('@/components/systemAdmin/PageManagement/MyPageManagement/LoginTopPageCreationCreate.vue') },
            { path: 'login-top-page-creation-edit', component: () => import('@/components/systemAdmin/PageManagement/MyPageManagement/LoginTopPageCreationEdit.vue') },
            { path: 'login-top-page-settings', component: () => import('@/components/systemAdmin/PageManagement/MyPageManagement/LoginTopPageSettings.vue') },
            { path: 'login-top-page-auto-update-settings', component: () => import('@/components/systemAdmin/PageManagement/MyPageManagement/LoginTopPageAutoUpdateSettings.vue') },
          ],
        },
        {
          path: 'appeal-page',
          component: () => import('@/components/systemAdmin/PageManagement/AppealPage/AppealPage.vue'),
          children: [
            { path: 'member-level-specific-page-settings', component: () => import('@/components/systemAdmin/PageManagement/AppealPage/MemberLevelSpecificPageSettings.vue') },
            { path: 'page-design-creation', component: () => import('@/components/systemAdmin/PageManagement/AppealPage/PageDesignCreation.vue') },
            { path: 'page-design-creation-create', component: () => import('@/components/systemAdmin/PageManagement/AppealPage/PageDesignCreationCreate.vue') },
            { path: 'page-design-creation-edit', component: () => import('@/components/systemAdmin/PageManagement/AppealPage/PageDesignCreationEdit.vue') },
          ],
        },
        {
          path: 'individual-page-management',
          component: () => import('@/components/systemAdmin/PageManagement/IndividualPageManagement/IndividualPageManagement.vue'),
          children: [
            { path: 'member-individual-page', component: () => import('@/components/systemAdmin/PageManagement/IndividualPageManagement/MemberIndividualPage.vue') },
            { path: 'member-individual-page-create', component: () => import('@/components/systemAdmin/PageManagement/IndividualPageManagement/MemberIndividualPageCreate.vue') },
            { path: 'member-individual-page-edit', component: () => import('@/components/systemAdmin/PageManagement/IndividualPageManagement/MemberIndividualPageEdit.vue') },
            { path: 'non-member-individual-page', component: () => import('@/components/systemAdmin/PageManagement/IndividualPageManagement/NonMemberIndividualPage.vue') },
            { path: 'non-member-individual-page-create', component: () => import('@/components/systemAdmin/PageManagement/IndividualPageManagement/NonMemberIndividualPageCreate.vue') },
            { path: 'non-member-individual-page-edit', component: () => import('@/components/systemAdmin/PageManagement/IndividualPageManagement/NonMemberIndividualPageEdit.vue') },
            { path: 'individual-page-group', component: () => import('@/components/systemAdmin/PageManagement/IndividualPageManagement/IndividualPageGroup.vue') },
            { path: 'individual-page-group-create', component: () => import('@/components/systemAdmin/PageManagement/IndividualPageManagement/IndividualPageGroupCreate.vue') },
            { path: 'individual-page-group-edit', component: () => import('@/components/systemAdmin/PageManagement/IndividualPageManagement/IndividualPageGroupEdit.vue') },
          ],
        },
        {
          path: 'design-management',
          component: () => import('@/components/systemAdmin/PageManagement/DesignManagement/DesignManagement.vue'),
          children: [
            { path: 'sp-css-settings', component: () => import('@/components/systemAdmin/PageManagement/DesignManagement/SPCSSSettings.vue') },
          ],
        },
        {
          path: 'image-management',
          component: () => import('@/components/systemAdmin/PageManagement/ImageManagement/ImageManagement.vue'),
          children: [
            { path: 'image-warehouse', component: () => import('@/components/systemAdmin/PageManagement/ImageManagement/ImageWarehouse.vue') },
            { path: 'image-warehouse-create', component: () => import('@/components/systemAdmin/PageManagement/ImageManagement/ImageWarehouseCreate.vue') },
            { path: 'image-warehouse-edit', component: () => import('@/components/systemAdmin/PageManagement/ImageManagement/ImageWarehouseEdit.vue') },
            { path: 'image-group', component: () => import('@/components/systemAdmin/PageManagement/ImageManagement/ImageGroup.vue') },
            { path: 'image-group-create', component: () => import('@/components/systemAdmin/PageManagement/ImageManagement/ImageGroupCreate.vue') },
            { path: 'image-group-edit', component: () => import('@/components/systemAdmin/PageManagement/ImageManagement/ImageGroupEdit.vue') },
          ],
        },
      ],
    },
    {
      path: 'point-management',
      component: () => import('@/components/systemAdmin/PointManagement/PointManagement.vue'),
      children: [
        { path: 'add-points-settings', component: () => import('@/components/systemAdmin/PointManagement/AddPointsSettings.vue') },
        { path: 'consume-points-settings', component: () => import('@/components/systemAdmin/PointManagement/ConsumePointsSettings.vue') },
        { path: 'consume-points-settings-csv', component: () => import('@/components/systemAdmin/PointManagement/ConsumePointsSettingsCSV.vue') },
        { path: 'special-consume-points-settings', component: () => import('@/components/systemAdmin/PointManagement/SpecialConsumePointsSettings.vue') },
        { path: 'special-consume-points-settings-csv', component: () => import('@/components/systemAdmin/PointManagement/SpecialConsumePointsSettingsCSV.vue') },
        { path: 'ng-word-settings', component: () => import('@/components/systemAdmin/PointManagement/NGWordSettings.vue') },
        { path: 'ng-word-settings-create', component: () => import('@/components/systemAdmin/PointManagement/NGWordSettingsCreate.vue') },
        { path: 'ng-word-settings-edit', component: () => import('@/components/systemAdmin/PointManagement/NGWordSettingsEdit.vue') },
      ],
    },
    {
      path: 'site-management',
      component: () => import('@/components/systemAdmin/SiteManagement/SiteSetting/SiteManagement.vue'),
      children: [
        { path: 'site-settings', component: () => import('@/components/systemAdmin/SiteManagement/SiteSetting/SiteSettings.vue') },
        { path: 'account-settings', component: () => import('@/components/systemAdmin/SiteManagement/SiteSetting/AccountSettings.vue') },
        { path: 'account-setting-create', component: () => import('@/components/systemAdmin/SiteManagement/SiteSetting/Account/AccountSettingCreate.vue') },
        { path: 'account-setting-edit/:id?', component: () => import('@/components/systemAdmin/SiteManagement/SiteSetting/Account/AccountSettingEdit.vue') },

      ],
    },
    {
      path: 'staff-management',
      component: () => import('@/components/systemAdmin/SiteManagement/StaffManagement/StaffManagement.vue'),
      children: [
        { path: 'staff-management', component: () => import('@/components/systemAdmin/SiteManagement/StaffManagement/StaffManagementView.vue') },
        { path: 'staff-management-create', component: () => import('@/components/systemAdmin/SiteManagement/StaffManagement/StaffCreate.vue') },
        { path: 'staff-management-edit/:id?', component: () => import('@/components/systemAdmin/SiteManagement/StaffManagement/StaffEdit.vue') },
      ],
    },
    {
      path: 'access-restriction-settings',
      component: () => import('@/components/systemAdmin/SiteManagement/StaffManagement/StaffManagement.vue'),
      children: [
        
        { path: 'access-restriction-settings', component: () => import('@/components/systemAdmin/SiteManagement/AccessRestrictionSetting/AccessRestrictionSettings.vue') },
        { path: 'access-restriction-settings-create', component: () => import('@/components/systemAdmin/SiteManagement/AccessRestrictionSetting/AccessRestrictionSettingsCreate.vue') },
        { path: 'access-restriction-settings-edit/:id?', component: () => import('@/components/systemAdmin/SiteManagement/AccessRestrictionSetting/AccessRestrictionSettingsEdit.vue') },
      ],
    },
    {
      path: 'system-settings',
      component: () => import('@/components/systemAdmin/SiteManagement/SystemSettings/SystemSettings.vue'),
      children: [
        { path: 'system-settings', component: () => import('@/components/systemAdmin/SiteManagement/SystemSettings/SystemSettingsView.vue') },
        { path: 'convert-management', 
          component: () => import('@/components/systemAdmin/SiteManagement/SystemSettings/ConvertManagement.vue'),
          children: [
            { path: 'send-setting', component: () => import('@/components/systemAdmin/SiteManagement/SystemSettings/ConvertManagementSendSetting.vue') },
            { path: 'send-setting-create', component: () => import('@/components/systemAdmin/SiteManagement/SystemSettings/ConvertManagementSendSettingCreate.vue') },
            { path: 'send-setting-edit', component: () => import('@/components/systemAdmin/SiteManagement/SystemSettings/ConvertManagementSendSettingEdit.vue') },
            { path: 'send-history', component: () => import('@/components/systemAdmin/SiteManagement/SystemSettings/ConvertManagementSendHistory.vue') },
          ]
        },
      ],
    },
    {
      path: 'relay-management',
      component: () => import('@/components/systemAdmin/SiteManagement/RelayManagement/RelayManagement.vue'),
      children: [
        { path: 'relay-settings', component: () => import('@/components/systemAdmin/SiteManagement/RelayManagement/RelaySettings.vue') },
        { path: 'mail-send-monitoring', component: () => import('@/components/systemAdmin/SiteManagement/RelayManagement/MailSendMonitoring.vue') },
      ],
    },
    {
      path: 'system-domain-management',
      component: () => import('@/components/systemAdmin/SiteManagement/SystemDomainManagement/SystemDomainManagement.vue'),
      children: [
        { path: 'domain-management-in-text', component: () => import('@/components/systemAdmin/SiteManagement/SystemDomainManagement/DomainManagementInText.vue') },
        { path: 'domain-management-in-text-create', component: () => import('@/components/systemAdmin/SiteManagement/SystemDomainManagement/DomainManagementInTextCreate.vue') },
        { path: 'from-domain-management', component: () => import('@/components/systemAdmin/SiteManagement/SystemDomainManagement/FromDomainManagement.vue') },
        { path: 'from-domain-management-create', component: () => import('@/components/systemAdmin/SiteManagement/SystemDomainManagement/FromDomainManagementCreate.vue') },
        { path: 'domain-check', component: () => import('@/components/systemAdmin/SiteManagement/SystemDomainManagement/DomainCheck.vue') },
      ],
    },
    {
      path: 'affiliate-management',
      component: () => import('@/components/systemAdmin/AffiliateManagement/AffiliateManagement.vue'),
      children: [
        { path: 'affiliate-settings', component: () => import('@/components/systemAdmin/AffiliateManagement/AffiliateSettings.vue') },
        { path: 'affiliate-history', component: () => import('@/components/systemAdmin/AffiliateManagement/AffiliateHistory.vue') },
      ],
    },
    {
      path: 'rejection-management',
      component: () => import('@/components/systemAdmin/SiteManagement/RejectionManagement/RejectionManagement.vue'),
      children: [
        { path: 'registration-rejection-address-settings', component: () => import('@/components/systemAdmin/SiteManagement/RejectionManagement/RegistrationRejectionAddressSettings.vue') },
        { path: 'registration-rejection-address-settings-create', component: () => import('@/components/systemAdmin/SiteManagement/RejectionManagement/RegistrationRejectionAddressSettingsCreate.vue') },
        { path: 'registration-rejection-phone-number-settings', component: () => import('@/components/systemAdmin/SiteManagement/RejectionManagement/RegistrationRejectionPhoneNumberSettings.vue') },
        { path: 'registration-rejection-phone-number-settings-create', component: () => import('@/components/systemAdmin/SiteManagement/RejectionManagement/RegistrationRejectionPhoneNumberSettingsCreate.vue') },
        { path: 'remail-rejection-address-settings', component: () => import('@/components/systemAdmin/SiteManagement/RejectionManagement/RemailRejectionAddressSettings.vue') },
        { path: 'remail-rejection-address-settings-create', component: () => import('@/components/systemAdmin/SiteManagement/RejectionManagement/RemailRejectionAddressSettingsCreate.vue') },
        { path: 'remail-rejection-phone-number-settings', component: () => import('@/components/systemAdmin/SiteManagement/RejectionManagement/RemailRejectionPhoneNumberSettings.vue') },
        { path: 'remail-rejection-phone-number-settings-create', component: () => import('@/components/systemAdmin/SiteManagement/RejectionManagement/RemailRejectionPhoneNumberSettingsCreate.vue') },
      ],
    },
    {
      path: 'mail-queue-management',
      component: () => import('@/components/systemAdmin/SiteManagement/MailQueueManagement/MailQueueManagement.vue'),
      children: [
        { path: 'mail-queue', component: () => import('@/components/systemAdmin/SiteManagement/MailQueueManagement/MailQueue.vue') },
      ],
    },
    {
      path: 'custom-api-function',
      component: () => import('@/components/systemAdmin/SiteManagement/CustomAPIFunction/CustomAPIFunction.vue'),
      children: [
        { path: 'ip-address-registration', component: () => import('@/components/systemAdmin/SiteManagement/CustomAPIFunction/IPAddressRegistration.vue') },
      ],
    },
];
  
export default systemAdminRoutes;
  